import { motion } from "framer-motion";
import "../css/address-button.css";
import React, { useState } from "react";

export default function AddressButton() {
    const [tooltipText, setTooltipText] = useState("Copy to Clipboard");

    // Copy address to clipboard
    async function copyAddress() {
        try {
            await navigator.clipboard.writeText("0xfc169284da39e8d337d01ab011c3e5d92f7ddba6");
            setTooltipText("Copied!"); // Provide feedback that the text was copied
            setTimeout(() => setTooltipText("Copy to Clipboard"), 2000); // Reset tooltip text after 2 seconds
        } catch (err) {
            console.error("Failed to copy: ", err);
            setTooltipText("Failed to copy"); // Provide feedback that the copy failed
            setTimeout(() => setTooltipText("Copy to Clipboard"), 2000); // Reset tooltip text after 2 seconds
        }
    }

    return (
        <>
            <div className="tooltip">
                <span className="tooltiptext">{tooltipText}</span>
                <motion.button whileTap={{ scale: 0.9 }} className="tech-button token-address" onClick={copyAddress}>
                    <p>0xfc16...7ddba6</p>
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.4 20H9.6C9.26863 20 9 19.7314 9 19.4V9.6C9 9.26863 9.26863 9 9.6 9H19.4C19.7314 9 20 9.26863 20 9.6V19.4C20 19.7314 19.7314 20 19.4 20Z"></path>
                        <path d="M15 9V4.6C15 4.26863 14.7314 4 14.4 4H4.6C4.26863 4 4 4.26863 4 4.6V14.4C4 14.7314 4.26863 15 4.6 15H9"></path>
                    </svg>
                </motion.button>
            </div>
        </>
    );
}
