import React, { useState } from 'react';
import Modal from './Modal';
import "./css/email.css";
import AddressButton from './components/AddressButton';

function Email() {
    const [email, setEmail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setMessage("Please enter a valid email address.");
            setIsModalOpen(true);
            return;
        }

        try {
            const response = await fetch('https://genrize.io/submitEmailAPI.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email, walletAddress: walletAddress }),
            });

            if (response.ok) {
                const result = await response.json();
                setMessage(result.message);
                setEmail('');
                setWalletAddress('');
                setIsModalOpen(true);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage(`An error occurred: ${error.message}`);
            setIsModalOpen(true);
        }
    };

    return (
        <>
            <section id="email-screen">
                <div id="logo-header">
                    <svg xmlns="http://www.w3.org/2000/svg" id="logo-svg" viewBox="0 0 1000 640">
                        <defs>
                            <linearGradient id="goldgrad" x1="0%" y1="0%" x2="100%" y2="0%" gradientTransform="rotate(45)" gradientUnits="userSpaceOnUse">
                                <stop offset="0%" stopColor="rgba(255, 255, 255, 0.85)" />
                                <stop offset="100%" stopColor="rgba(255, 255, 255, 0.85)" />
                            </linearGradient>
                        </defs>
                        <path id="logo-svg-body" fill="url(#goldgrad)" d="M614.76,202.99c-9.74,26.57-29.09,41.61-37.47,47.11-1-10.53-7.14-19.57-15.88-24.57-4.65-2.67-10.04-4.2-15.79-4.2-.65,0-1.3.02-1.94.06-7.94.48-15.11,3.87-20.43,9.14-5.83,5.76-9.45,13.77-9.45,22.62,0,10.14,4.74,19.18,12.14,24.99.23.19.47.37.71.55-.23.37-.53.84-.87,1.41-3.47,5.7-12.17,21.24-16.22,39.68-4.21,19.17-6.63,41.03-7.98,57.48-1.23,14.88-1.58,25.32-1.58,25.32,0,0-.35-10.44-1.58-25.32-1.35-16.45-3.77-38.31-7.98-57.48-4.05-18.44-12.75-33.98-16.22-39.68-.34-.57-.64-1.04-.87-1.41.24-.18.48-.36.71-.55,7.4-5.81,12.14-14.85,12.14-24.99,0-8.85-3.62-16.86-9.45-22.62-5.32-5.27-12.49-8.66-20.43-9.14-.64-.04-1.29-.06-1.94-.06-5.75,0-11.14,1.53-15.79,4.2-8.74,5-14.88,14.04-15.88,24.57-8.38-5.5-27.73-20.54-37.47-47.11-4.27-10.67-4.01-21.84-1.13-33.18,9.02.35,17.83,1.57,26.33,3.57-7.45-4.75-15.39-8.8-23.72-12.06,5.98-16.74,16.61-33.65,25.87-49.62,5.26,3.34,10.28,7.03,15.03,11.04-3.3-6.19-7.06-12.1-11.23-17.68.2-.36.4-.73.6-1.09,17.43-31.48,29.86-63.97,29.86-63.97,0,0,19.3,36.62,17.76,74.54-.69,16.85-9.38,22.9-14.62,32.13-.78,1.35-1.47,2.78-2.06,4.32-6.73,17.67-.58,30.41,9.67,38.29,4.86,3.21,9.82,4.34,14.53,3.56,3.75-.61,7.34-2.44,10.59-5.41,10.27-9.35,24.29-9.35,34.56,0,3.25,2.97,6.84,4.8,10.59,5.41,4.71.78,9.67-.35,14.53-3.56,10.25-7.88,16.4-20.62,9.67-38.29-.59-1.54-1.28-2.97-2.06-4.32-5.24-9.23-13.93-15.28-14.62-32.13-1.54-37.92,17.76-74.54,17.76-74.54,0,0,12.43,32.49,29.86,63.97.2.36.4.73.6,1.09-4.17,5.58-7.93,11.49-11.23,17.68,4.75-4.01,9.77-7.7,15.03-11.04,9.26,15.97,19.89,32.88,25.87,49.62-8.33,3.26-16.27,7.31-23.72,12.06,8.5-2,17.31-3.22,26.33-3.57,2.88,11.34,3.14,22.51-1.13,33.18Z" />
                        <path id="logo-svg-ring" fill="url(#goldgrad)" d="M580,328.74c-1.43,0-2.81-.77-3.53-2.12-1.04-1.95-.3-4.37,1.65-5.41,54.41-29,88.21-85.28,88.21-146.88,0-91.71-74.62-166.33-166.33-166.33s-166.33,74.62-166.33,166.33c0,61.6,33.8,117.88,88.21,146.88,1.95,1.04,2.69,3.46,1.65,5.41-1.04,1.95-3.46,2.69-5.41,1.65-57.02-30.4-92.45-89.38-92.45-153.94C325.67,78.2,403.87,0,500,0s174.33,78.2,174.33,174.33c0,64.56-35.42,123.54-92.45,153.94-.6.32-1.24.47-1.88.47Z" />
                        <g id="logo-svg-text" fill="url(#goldgrad)">
                            <path id="g" className="cls-1" d="M0,590.4v-44.7c0-30.62,14.49-49.81,48.38-49.81h46.95c31.84,0,46.54,15.92,48.17,43.28.2,3.47-1.63,5.31-4.9,5.31h-18.37c-3.27,0-4.9-1.84-5.1-5.1-1.22-13.27-7.14-16.33-19.8-16.33h-46.95c-14.09,0-20.01,3.88-20.01,21.64v46.75c0,17.56,6.33,21.43,20.01,21.43h46.95c13.68,0,20-3.88,20-21.43v-6.94h-35.11c-3.27,0-5.1-1.84-5.1-5.1v-14.7c0-3.27,1.84-5.1,5.1-5.1h58.38c3.27,0,5.1,1.84,5.1,5.1v25.72c0,30.42-14.49,49.6-48.38,49.6h-46.95c-33.89,0-48.38-19.19-48.38-49.6Z" />
                            <path id="e" className="cls-1" d="M181.07,634.9v-133.91c0-3.27,1.84-5.1,5.1-5.1h111.66c3.27,0,5.1,1.84,5.1,5.1v16.94c0,3.27-1.84,5.1-5.1,5.1h-88.59v31.44h57.36c3.27,0,5.1,1.84,5.1,5.1v14.9c0,3.27-1.84,5.1-5.1,5.1h-57.36v33.27h88.59c3.27,0,5.1,1.84,5.1,5.1v16.94c0,3.27-1.84,5.1-5.1,5.1h-111.66c-3.27,0-5.1-1.84-5.1-5.1Z" />
                            <path id="n" className="cls-1" d="M337.64,634.9v-133.91c0-3.27,1.84-5.1,5.1-5.1h18.37c2.65,0,4.7,1.02,6.33,3.06l79.2,98.39v-96.35c0-3.27,1.84-5.1,5.1-5.1h18.17c3.27,0,5.1,1.84,5.1,5.1v133.91c0,3.27-1.84,5.1-5.1,5.1h-18.37c-2.65,0-4.7-1.02-6.33-3.06l-79.2-97.78v95.74c0,3.27-1.84,5.1-5.1,5.1h-18.17c-3.27,0-5.1-1.84-5.1-5.1Z" />
                            <path id="r" className="cls-1" d="M615.81,551.01v-3.08c0-34.5-15.91-52.06-57.76-52.06h-46.04c-2.02,0-3.66,1.64-3.66,3.66v19.83c0,2.02,1.64,3.66,3.66,3.66h43.8c22.24,0,31.43,3.89,31.43,25.11v2.65c0,20.84-9.59,24.5-31.43,24.5h-43.8c-2.02,0-3.66,1.64-3.66,3.66v19.62c0,2.02,1.64,3.66,3.66,3.66h45.43l22.24,34.5c1.43,2.24,3.48,3.26,6.13,3.26h21.22c4.1,0,5.72-2.65,3.28-6.11l-24.3-34.5c21.83-6.54,29.8-23.48,29.8-48.37Z" />
                            <path id="i" className="cls-1" d="M651.34,634.9v-133.91c0-3.27,1.84-5.1,5.1-5.1h17.96c3.27,0,5.1,1.84,5.1,5.1v133.91c0,3.27-1.84,5.1-5.1,5.1h-17.96c-3.27,0-5.1-1.84-5.1-5.1Z" />
                            <path id="z" className="cls-1" d="M712.78,634.9v-17.76c0-2.45.82-4.49,2.66-6.33l89.2-87.57h-86.76c-3.27,0-5.1-1.84-5.1-5.1v-17.15c0-3.27,1.84-5.1,5.1-5.1h121.46c3.27,0,5.1,1.84,5.1,5.1v18.17c0,2.45-.82,4.49-2.65,6.33l-89.2,87.37h87.16c3.27,0,5.1,1.84,5.1,5.1v16.94c0,3.27-1.84,5.1-5.1,5.1h-121.87c-3.27,0-5.1-1.84-5.1-5.1Z" />
                            <path id="e2" className="cls-1" d="M878.13,634.9v-133.91c0-3.27,1.84-5.1,5.1-5.1h111.66c3.27,0,5.1,1.84,5.1,5.1v16.94c0,3.27-1.84,5.1-5.1,5.1h-88.59v31.44h57.36c3.27,0,5.1,1.84,5.1,5.1v14.9c0,3.27-1.84,5.1-5.1,5.1h-57.36v33.27h88.59c3.27,0,5.1,1.84,5.1,5.1v16.94c0,3.27-1.84,5.1-5.1,5.1h-111.66c-3.27,0-5.1-1.84-5.1-5.1Z" />
                        </g>
                    </svg>
                    <h1 className="header-title">AirDrop Complete</h1>
                </div>
                <div id="content-wrapper">
                    <div className="left-column">
                        
                        <img src="https://airdrop.genrize.io/Assets/spiral.jpeg" alt="Spiral Anomaly" className="airdrop-image"/>
                        <p className="instruction-text">Here is the address for the ERC20 $RIZE token. You can add it to your wallet to see your tokens when they arrive. The Symbol is RIZE and it has 18 decimals.</p>
                        <p className="instruction-text">Contract Address: <span className='highlight-text'>0xfc169284da39e8d337d01ab011c3e5d92f7ddba6</span></p>
                        <AddressButton /> {/* button to copy contract address */}
                        
                    </div>
                    <div className="right-column">
                        <form onSubmit={handleSubmit}>
                            <div className="tech-box">
                                <p>
                                    GenRize AirDropped 10,000 $RIZE tokens to the first 300 wallets that signed up. Enter your email below to get alerts and updates so you don't miss the next opportunity to participate. No spam, we promise.
                                    <span className="highlight-text"></span>!
                                </p>
                                <div className="button-group">
                                    <a href="https://buy.genrize.io/" className="button">BUY RIZE NOW</a>
                                    <a href="https://genrize.io/" className="button">Landing Page</a>
                                    <a href="https://docs.genrize.io/white-paper/" className="button">White Paper</a>
                                </div>
                                <input placeholder="Your Email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                {/* <input placeholder="ETH Wallet Address" type="text" name="walletAddress" value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} required /> */}
                            </div>
                            <div className='button-container'>
                                <button type="submit" className="signup-button">JOIN WHITELIST!</button>
                            </div>
                        </form>
                        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                            {message && <div className="confirmation-message">{message}</div>}
                        </Modal>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Email;
