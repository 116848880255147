import logo from './favicon.svg';
import './css/email.css';
import Email from './Email';

function App() {
    return ( <
        div className = "App" >
        <
        Email / >
        <
        /div>
    );
}

export default App;